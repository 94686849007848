import { ContentAttributes, SmallEntryWithDescription, TextOrPlainText } from 'chemistry'
import {
  COST_KEY,
  COST_MAPPER,
  DIFFICULTY_KEY,
  DIFFICULTY_MAPPER,
  PAGE,
  READ_THIS_KEY,
  TIME_TO_READ_KEY
} from 'common/constants'
import React from 'react'

export function toGridItemComponent(translations) {
  return props => {
    const contentAttributesMapper = mapAttributes(translations)
    const {
      data: { id, description, url, imageUrl, metadata },
      value
    } = props

    const readThis = translations[READ_THIS_KEY]

    const type = translations[`${PAGE}.${metadata?.type}`]
    const ctaCopy = `${readThis} ${type}`
    const attributes = [...contentAttributesMapper(props?.data?.metadata)]

    return (
      <SmallEntryWithDescription
        key={id}
        item={{
          image: {
            image: {
              url: imageUrl
            },
            styles: {
              img: {
                maxWidth: '100%',
                minHeight: '100%',
                height: 'auto'
              }
            },
            imageAlt: value
          },
          title: value,
          externalUrl: url,
          description,
          ctaCopy,
          overlineCaption: translations[`${PAGE}.${metadata?.type}`] ?? metadata?.type,
          extraBox: !!attributes?.length && {
            component: ContentAttributes,
            props: {
              attributes,
              p: '8px 0',
              columnGap: '16px',
              mb: '12px',
              float: 'none'
            }
          }
        }}
      />
    )
  }
}

function mapAttributes(translations) {
  const getTranslatedLabel = key => translations[key] ?? `missing translation for ${key}`

  return metadata => {
    const cost = metadata?.attribute_cost
    const difficulty = metadata?.attribute_difficulty
    const timeToRead = metadata?.attribute_timeToRead
    const attributes = []

    if (cost) {
      attributes.push({
        label: getTranslatedLabel(COST_KEY),
        type: 'icons',
        icon: 'euro',
        value: COST_MAPPER[cost]
      })
    }

    if (difficulty) {
      attributes.push({
        label: getTranslatedLabel(DIFFICULTY_KEY),
        type: 'icons',
        icon: 'hammer',
        value: DIFFICULTY_MAPPER[difficulty]
      })
    }

    if (timeToRead) {
      attributes.push({
        label: getTranslatedLabel(TIME_TO_READ_KEY),
        type: 'text',
        value: TextOrPlainText({ text: timeToRead })
      })
    }

    return attributes
  }
}
